<script>
import api from '@/services/api';
import VueApexCharts from 'vue-apexcharts';
import { BCol, BRow } from 'bootstrap-vue';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { prefixZeroToNum } from '@/util/helper';

export default {
    name: 'PaynetStatistics',
    components: { ProgressLinear, BRow, BCol, apexchart: VueApexCharts },
    data: () => ({
        // count
        countSuccess: {
            loaded: false,
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2
                    },
                    offsetX: -5,
                    height: 300
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ebe9f1',
                    padding: {
                        top: -20,
                        bottom: 0,
                        left: 20
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#7367f0', '#00E296', '#FA6B37'],
                markers: {
                    size: 3,
                    hover: {
                        size: 5
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    labels: {
                        offsetY: 0
                    },
                    categories: [],
                    tickPlacement: 'on'
                },
                tooltip: {
                    x: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 220
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            series: [
                {
                    name: 'Oltin Baliq',
                    data: []
                },
                {
                    name: 'Al Chiroq',
                    data: []
                },
                {
                    name: 'Total',
                    data: []
                }
            ]
        },
        countFail: {
            loaded: false,
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2
                    },
                    offsetX: -5,
                    height: 300
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ebe9f1',
                    padding: {
                        top: -20,
                        bottom: 0,
                        left: 20
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#7367f0', '#00E296', '#FA6B37'],
                markers: {
                    size: 3,
                    hover: {
                        size: 5
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    labels: {
                        offsetY: 0
                    },
                    categories: [],
                    tickPlacement: 'on'
                },
                tooltip: {
                    x: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 220
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            series: [
                {
                    name: 'Oltin Baliq',
                    data: []
                },
                {
                    name: 'Al Chiroq',
                    data: []
                },
                {
                    name: 'Total',
                    data: []
                }
            ]
        },
        // amount
        amountSuccess: {
            loaded: false,
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2
                    },
                    offsetX: -5,
                    height: 300
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ebe9f1',
                    padding: {
                        top: -20,
                        bottom: 0,
                        left: 20
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#7367f0', '#00E296', '#FA6B37'],
                markers: {
                    size: 3,
                    hover: {
                        size: 5
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    labels: {
                        offsetY: 0
                    },
                    categories: [],
                    tickPlacement: 'on'
                },
                tooltip: {
                    x: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 220
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            series: [
                {
                    name: 'Oltin Baliq',
                    data: []
                },
                {
                    name: 'Al Chiroq',
                    data: []
                },
                {
                    name: 'Total',
                    data: []
                }
            ]
        },
        amountFail: {
            loaded: false,
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2
                    },
                    offsetX: -5,
                    height: 300
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ebe9f1',
                    padding: {
                        top: -20,
                        bottom: 0,
                        left: 20
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#7367f0', '#00E296', '#FA6B37'],
                markers: {
                    size: 3,
                    hover: {
                        size: 5
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    labels: {
                        offsetY: 0
                    },
                    categories: [],
                    tickPlacement: 'on'
                },
                tooltip: {
                    x: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 220
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            series: [
                {
                    name: 'Oltin Baliq',
                    data: []
                },
                {
                    name: 'Al Chiroq',
                    data: []
                },
                {
                    name: 'Total',
                    data: []
                }
            ]
        }
    }),
    methods: {
        async getCountSuccess() {
            const { data } = await api.paynetStatistics.getStatsCountSuccessApi();
            this.countSuccess.series[0].data = data.apps['Oltin-Baliq'];
            this.countSuccess.series[1].data = data.apps['Al-chiroq'];
            this.countSuccess.series[2].data = data.total;
            this.countSuccess.loaded = true;

            data.dates.map((item) => {
                const date = new Date(item);
                const day = prefixZeroToNum(date.getDate());
                const month = prefixZeroToNum(date.getMonth() + 1);
                this.countSuccess.chartOptions.xaxis.categories.push(`${day}.${month}`);
            });
        },
        async getCountFail() {
            const { data } = await api.paynetStatistics.getStatsCountFailApi();
            this.countFail.series[0].data = data.apps['Oltin-Baliq'];
            this.countFail.series[1].data = data.apps['Al-chiroq'];
            this.countFail.series[2].data = data.total;
            this.countFail.loaded = true;

            data.dates.map((item) => {
                const date = new Date(item);
                const day = prefixZeroToNum(date.getDate());
                const month = prefixZeroToNum(date.getMonth() + 1);
                this.countFail.chartOptions.xaxis.categories.push(`${day}.${month}`);
            });
        },

        async getAmountSuccess() {
            const { data } = await api.paynetStatistics.getStatsAmountSuccessApi();
            this.amountSuccess.series[0].data = data.apps['Oltin-Baliq'];
            this.amountSuccess.series[1].data = data.apps['Al-chiroq'];
            this.amountSuccess.series[2].data = data.total;
            this.amountSuccess.loaded = true;

            data.dates.map((item) => {
                const date = new Date(item);
                const day = prefixZeroToNum(date.getDate());
                const month = prefixZeroToNum(date.getMonth() + 1);
                this.amountSuccess.chartOptions.xaxis.categories.push(`${day}.${month}`);
            });
        },
        async getAmountFail() {
            const { data } = await api.paynetStatistics.getStatsAmountFailApi();
            this.amountFail.series[0].data = data.apps['Oltin-Baliq'];
            this.amountFail.series[1].data = data.apps['Al-chiroq'];
            this.amountFail.series[2].data = data.total;
            this.amountFail.loaded = true;

            data.dates.map((item) => {
                const date = new Date(item);
                const day = prefixZeroToNum(date.getDate());
                const month = prefixZeroToNum(date.getMonth() + 1);
                this.amountFail.chartOptions.xaxis.categories.push(`${day}.${month}`);
            });
        }
    },
    mounted() {
        Promise.any([
            this.getCountSuccess(),
            this.getCountFail(),
            this.getAmountSuccess(),
            this.getAmountFail()
        ]);
    }
};
</script>

<template>
    <div>
        <b-row>
            <!--      Counts-->
            <b-col lg="12" class="mb-1">
                <div class="card mb-0">
                    <h3 class="m-2">Count Success</h3>
                    <progress-linear v-if="!countSuccess.loaded" />
                    <apexchart
                        v-else
                        type="line"
                        :options="countSuccess.chartOptions"
                        :series="countSuccess.series"
                    />
                </div>
            </b-col>
            <b-col lg="12" class="mb-1">
                <div class="card mb-0">
                    <h3 class="m-2">Count Fail</h3>
                    <progress-linear v-if="!countFail.loaded" />
                    <apexchart
                        v-else
                        type="line"
                        :options="countFail.chartOptions"
                        :series="countFail.series"
                    />
                </div>
            </b-col>

            <!--      Amounts-->
            <b-col lg="12" class="mb-1">
                <div class="card mb-0">
                    <h3 class="m-2">Amount Success</h3>
                    <progress-linear v-if="!amountSuccess.loaded" />
                    <apexchart
                        v-else
                        type="line"
                        :options="amountSuccess.chartOptions"
                        :series="amountSuccess.series"
                    />
                </div>
            </b-col>
            <b-col lg="12" class="mb-1">
                <div class="card mb-0">
                    <h3 class="m-2">Amount Fail</h3>
                    <progress-linear v-if="!amountFail.loaded" />
                    <apexchart
                        v-else
                        type="line"
                        :options="amountFail.chartOptions"
                        :series="amountFail.series"
                    />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>
